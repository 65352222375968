<template>
  <div class="app-wrapper">
    <SiteUpdate />
    <ResponsiveNavbar />
    <main class="main-content">
      <ResponsiveHeader />
      <TeamSection />
      <MedischPedicureSection />
      <TarievenSection />
      <ContactComponent />
    </main>
    <FooterComponent />
  </div>
</template>

<script>

import ResponsiveNavbar from './components/Navbar.vue';
import SiteUpdate from './components/SiteUpdate.vue';
import ResponsiveHeader from './components/Header.vue';
import TeamSection from './components/Over.vue';
import MedischPedicureSection from './components/Medischpedicure.vue';
import TarievenSection from './components/Tarieven.vue';
import ContactComponent from './components/Contact.vue';
import FooterComponent from './components/Footer.vue';

export default {
  components: {
    SiteUpdate,
    ResponsiveNavbar,
    ResponsiveHeader,
    TeamSection,
    MedischPedicureSection,
    TarievenSection,
    ContactComponent,
    FooterComponent,
  },
};
</script>

<style>
/* Voeg hier styling toe indien nodig */
</style>
